.why-codexodus {
    text-align: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    margin-top: 60px;
}

.benefits-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.column {
    flex: 1;
    padding: 0 20px;
}

.separator {
    width: 2px;
    background-color: #f58220;
    /* Matching the orange line in the image */
    margin: 0 20px;
}

.benefitt {
    flex: 1;
    text-align: center;
    padding: 1rem;

    /* Darker accent color for the benefits */
    margin: 0 10px;
    /* Adds spacing between each benefit box */
    border-radius: 10px;
    /* Rounds the corners */
    color: white;
    text-align: justify;

}

h2 {
    font-size: 28px;
    color: #1a1a1a;
}

h3 {
    color: #f58220;
    font-size: 20px;
    margin-bottom: 10px;
}

.column.right h3 {
    color: #4a90e2;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: #4a4a4a;
}


/* Responsive Styles */

/* Tablet View (max-width: 768px) */
@media (max-width: 768px) {
    .benefits-container {
        flex-direction: column;
        align-items: center;
    }

    .column {
        padding: 0;
        text-align: center;
        margin-bottom: 20px;
    }

    .separator {
        display: none;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {
    .why-codexodus {
        padding: 10px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    .benefitt {
        padding: 1rem;
        margin-bottom: 15px;
        font-size: 0.9rem;
    }
}