/* Container Styles */
.placement-readiness-container {
    max-width: 1200px;
    margin: 75px auto;
    padding: 90px;
    background-color: #E4F0FC;
    border-radius: 10px;

    padding-bottom: 150px;
}

.placement-readiness-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    color: black;
}

.placement-readiness-content {
    display: flex;
    justify-content: space-between;
}

.placement-left-section {
    width: 45%;
    position: relative;
}

.placement-left-image {
    width: 100%;
    border-radius: 10px;
}

.placement-interview-prep {
    background-color: #4285f4;
    color: white;
    padding: 20px;
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    border-radius: 10px;
    text-align: center;
}

.placement-interview-prep h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.placement-interview-prep p {
    font-size: 1rem;
    margin: 10;
    color: white;
}

.placement-right-section {
    width: 50%;
    text-align: justify;
}

.placement-feature {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: row-reverse
}

.placement-feature-image {
    width: 200px;
    height: 100px;
    margin-left: 20px;
    border-radius: 10px;
}

.placement-feature-title {
    font-size: 1.3rem;
    color: #002350;
    margin-bottom: 10px;
}

.placement-feature-description {
    font-size: 16px;
    color: #231F20;
    font-weight: 400
}

/* Responsive Styles */
@media (max-width: 768px) {
    .placement-readiness-content {
        flex-direction: column;
        align-items: center;
    }

    .placement-left-section,
    .placement-right-section {
        width: 100%;
    }

    .placement-interview-prep {
        position: static;
        margin-top: 20px;
    }
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .placement-readiness-content {
        flex-direction: column;
        gap: 30px;
    }

    .placement-left-section {
        position: relative;
    }

    .placement-interview-prep {
        position: static;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .placement-readiness-container {
        padding: 20px;
        border-radius: 0px;
    }

    .placement-readiness-title {
        font-size: 1.8rem;
    }

    .placement-feature-image {
        width: 120px;
        height: 80px;
        margin-left: 0px;
        margin-bottom: 10px;
       
    }

    .placement-feature-description {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .placement-readiness-title {
        font-size: 1.5rem;
        margin-bottom: 30px;
    }

    .placement-interview-prep h3 {
        font-size: 1.2rem;
    }

    .placement-interview-prep p {
        font-size: 0.9rem;
    }

    .placement-feature {
        flex-direction: column;
        align-items: flex-start;
    }

    .placement-feature-image {
        width: 100%;
        height: auto;
    }
}