/* Default navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  background-color: white;
  border-bottom: 1px solid #eff0ef;
  justify-content: space-around;


  box-shadow: 0 2px 4px #0000001a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;



  gap: 100px
}

.navbar__logo-container {
  display: flex;
  align-items: center;
}

.navbar__logo {
  height: 40px;
  margin-right: 0.5rem;
}

.navbar__brand {
  font-size: 1.5rem;
  color: #4a4a4a;
  font-weight: bold;
}

.Navbar_x_text {
  font-size: 2rem;
}

.navbar__brand--highlight {
  color: #2563eb;
}

/* Hiding the menu and hamburger icon */
.navbar__menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.navbar__item {
  font-size: 1rem;
  color: #000;
  cursor: pointer;
  font-weight: 500;
}

.navbar__item:hover {
  color: #4285f3;
}

/* Styles for buttons */
.navbar__buttons {
  display: flex;
  gap: 1rem;
}

.navbar__button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
}

.navbar__button--primary {
  background-color: #4285f3;
  color: white;
  border: none;
}

.navbar__button--secondary {
  background-color: transparent;
  color: #4285f3;
  border: 2px solid #4285f3;
}

.navbar__button--primary:hover,
.navbar__button--secondary:hover {
  opacity: 0.8;
}

/* Hamburger icon */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #000;
  transition: 0.3s;
}

/* Mobile View */
@media (max-width: 768px) {

  .navbar__menu,
  .navbar__buttons {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .navbar__menu--active {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 100%;
    padding: 20px;
  }
}