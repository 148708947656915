.codexodus-offers-container {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 80px;
    background-color: #ffffff;
}

.main-title {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
}

.subtitle {
    font-size: 1.2rem;
    color: #ff8c00;
    margin-bottom: 2rem;
    font-weight: bold;
}

.offers-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
}

.offer-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 4px 0px 8px 0px #00000040;

    padding: 1.5rem;
    width: 20%;
    text-align: left;
}

.offer-title {
    font-size: 1.1rem;
    color: #333333;
    margin-bottom: 0.5rem;
}

.offer-details {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: #555555;
    font-size: 0.9rem;
}

.cta-section {
    background-color: #407bff;
    color: white;
    padding: 40px;
    border-radius: 8px;
    padding-top: 150px;
    margin-top: -14%;
}

.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #FFFFFF;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.get-started,
.talk-to-sales {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.get-started {
    background-color: white;
    color: #407bff;
    font-weight: bold;
}

.talk-to-sales {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

/* Responsive Styles */

/* Tablet View (max-width: 768px) */
@media (max-width: 768px) {
    .main-title {
        font-size: 1.8rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .offers-grid {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .offer-card {
        width: 80%;
    }

    .cta-section {
        padding-top: 100px;
    }

    .cta-section p {
        font-size: 1rem;
    }

    .cta-buttons {
        flex-direction: column;
    }

    .get-started,
    .talk-to-sales {
        width: 100%;
        padding: 0.8rem 1.5rem;
    }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {
    .main-title {
        font-size: 1.6rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .offers-grid {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
    }

    .offer-card {
        width: 90%;
        padding: 1rem;
    }

    .offer-title {
        font-size: 1rem;
    }

    .offer-details {
        font-size: 0.8rem;
    }

    .cta-section {
        padding-top: 30px;
    }

    .cta-section p {
        font-size: 1rem;
    }

    .cta-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }

    .get-started,
    .talk-to-sales {
        width: 100%;
        padding: 0.6rem 1.2rem;
    }

    .cta-section {
        margin-top: 3%;
    }
}