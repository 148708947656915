.features-container {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
}

h2 {
    font-size: 2rem;
    color: #f78e1e;
    margin-bottom: 40px;
}

.features-gridd {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 100px;
}

.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature-circle {
    background-color: #4285f4;
    color: white;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 15px;
}

.feature-circle span {
    color: white;
}

p {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .features-gridd {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
}

@media (max-width: 768px) {
    .features-gridd {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    h2 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 1.5rem;
    }

    .feature-circle {
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
    }

    p {
        font-size: 0.9rem;
    }
}