.hero-section {
    position: relative;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5%;
    margin-top: 80px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 40px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align content to the left */
}

.content-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 500px;
}

.contentt {
    text-align: left;
    color: #fff;
    max-width: 400px;
}

.contentt h2 {
    font-size: 28px;
    margin-bottom: 0px;
    color: #FF9547;
    text-align: justify;
    font-weight: 700;
}

.contentt h1 {
    font-size: 86px;
    margin-bottom: 20px;
    color: #FFFFFF;
    font-weight: 700;
}

.contentt p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #FFFFFF;
    font-weight: 600;
}

.buttonss {
    display: flex;
    gap: 15px;
}

.btn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
}

.btn.primary {
    background-color: #4285F3;
    color: #fff;
}

.btn.secondary {
    background-color: #f8f9fa;
    color: #4285F3;
    border: 1px solid #4285F3;
}

.btn:hover {
    opacity: 0.9;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .hero-section {
        height: 60vh;
        padding-left: 10%;
    }

    .content-container {
        max-width: 100%;
        padding: 0 20px;
        /* Adding padding for smaller screens */
    }

    .contentt h1 {
        font-size: 48px;
        margin-bottom: 10px;
    }

    .contentt h2 {
        font-size: 28px;
        margin-bottom: 5px;
    }

    .contentt p {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .buttonss {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .btn {
        width: 100%;
        padding: 12px 20px;
    }
}

/* Small screens and extra small mobile devices */
@media screen and (max-width: 480px) {
    .hero-section {
        height: 50vh;
        padding-left: 5%;
    }

    .contentt h1 {
        font-size: 36px;
    }

    .contentt h2 {
        font-size: 24px;
    }

    .contentt p {
        font-size: 0.9rem;
    }

    .buttonss {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .btn {
        width: 100%;
        padding: 10px;
    }

    .overlay {
        left: 0
    }
}