/* Container Styles */
.code-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;

    min-height: 65vh;
}

/* Card Styles */
.code-card {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 45%;
    display: flex;
    flex-direction: column;
}

.code-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.code-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.code-text-container {
    padding: 20px;
    text-align: center;
    height: 200px;
}

/* Vision and Mission Styles */
.code-vision {
    background-color: #4285F3;
    color: white;
}

.code-mission {
    background-color: #FF9547;
    color: white;
}

.code-heading {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #FFFFFF
}

.code-paragraph {
    font-size: 1rem;
    line-height: 1.5;
    color: #FFFFFF
}

/* Container Styles */
.code-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* Allow items to wrap on smaller screens */
    padding: 20px;
    gap: 20px;
    min-height: 65vh;
}

/* Card Styles */
.code-card {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 45%;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
}

.code-card:hover {
    transform: translateY(-5px);
}

@media (max-width: 768px) {
    .code-card {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .code-card {
        width: 100%;
    }
}

/* Image Container Styles */
.code-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.code-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Text Container Styles */
.code-text-container {
    padding: 20px;
    text-align: center;
}

/* Vision and Mission Styles */
.code-vision {
    background-color: #4285F3;
    color: white;
}

.code-mission {
    background-color: #FF9547;
    color: white;
}

.code-heading {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.code-paragraph {
    font-size: 1rem;
    line-height: 1.5;
}

/* Adjust font sizes for smaller screens */
@media (max-width: 768px) {
    .code-heading {
        font-size: 1.5rem;
    }

    .code-paragraph {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .code-heading {
        font-size: 1.3rem;
    }

    .code-paragraph {
        font-size: 0.85rem;
    }
}


/* Adjust font sizes for smaller screens */
@media (max-width: 768px) {
    .code-heading {
        font-size: 1.5rem;
    }

    .code-paragraph {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .code-heading {
        font-size: 1.3rem;
    }

    .code-paragraph {
        font-size: 0.85rem;
    }
}