.available-courses-container {
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #eaf3ff;
    padding: 80px;
    margin-top: 80px;
}

.title {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
}

.course-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    justify-items: center;
}

.course-card {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 1rem;
    width: 100%;
    max-width: 150px;
}

.course-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.course-title {
    font-size: 1rem;
    color: #333;
    margin: 0.5rem 0;
}

.badge {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: #23a6d5;
    color: #fff;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
}

/* Responsive Styles */

/* Tablet View (max-width: 768px) */
@media (max-width: 768px) {
    .available-courses-container {
        padding: 40px 10px;
    }

    .title {
        font-size: 1.8rem;
    }

    .course-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 1rem;
    }

    .course-card {
        max-width: 150px;
    }

    .course-title {
        font-size: 0.9rem;
    }
}

/* Mobile View (max-width: 480px) */
@media (max-width: 480px) {
    .available-courses-container {
        padding: 20px;
        margin-top: 20px;
    }

    .title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .course-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .course-card {
        max-width: 100%;
        padding: 0.8rem;
    }

    .course-title {
        font-size: 0.85rem;
    }

    .badge {
        font-size: 0.6rem;
        padding: 0.1rem 0.4rem;
    }
}