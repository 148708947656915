.student-advantage {

    padding: 80px;
    background-color: #e4ecf7;
    margin-top: 60px;
}

.header {
    text-align: left;
    margin-bottom: 30px;
}

.header h1 {
    font-size: 1.8em;
    color: #1a1a1a;
    margin-bottom: 10px;
}

.header p {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    font-weight: 400;
}

.advantages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.advantage-card {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    transition: transform 0.3s ease;
    height: 130px;
}

.advantage-card:hover {
    transform: translateY(-5px);
}

.badgee {
    background-color: #4a7eff;
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 12px;
    margin-bottom: 10px;
}

.advantage-card h2 {
    font-size: 1.1em;
    color: #333;
    font-weight: 500;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .student-advantage {
        padding: 20px;
    }

    .header h1 {
        font-size: 1.6em;
    }

    .header p {
        font-size: 0.9rem;
    }

    .advantages-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .advantage-card {
        padding: 15px;
        height: auto;
    }

    .advantage-card h2 {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 1.4em;
    }

    .header p {
        font-size: 0.85rem;
    }

    .student-advantage {
        padding: 10px;
    }

    .advantages-grid {
        gap: 10px;
    }

    .advantage-card {
        padding: 10px;
    }

    .advantage-card h2 {
        font-size: 0.95em;
    }
}