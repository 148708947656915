/* General Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

}

/* App Container */
.empower-app {
    text-align: center;

    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
}

.empower-title {
    color: #f18702;
    font-size: 2em;
    margin-bottom: 35px;
}

/* Features Grid */
.empower-features-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Feature Card */
.empower-feature-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 4px 8px 20px 0px #0000001A;

    transition: transform 0.3s ease;
    text-align: justify;
}

.empower-feature-card:hover {
    transform: translateY(-5px);
}

.empower-feature-card h2 {
    color: #000000;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.empower-feature-card p {
    color: #555;
    font-size: 0.9em;
    font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .empower-title {
        font-size: 1.8em;
        margin-bottom: 25px;
    }

    .empower-feature-card {
        padding: 15px;
    }

    .empower-feature-card h2 {
        font-size: 1.1em;
    }

    .empower-feature-card p {
        font-size: 0.85em;
    }
}

@media (max-width: 480px) {
    .empower-title {
        font-size: 1.6em;
        margin-bottom: 20px;
    }

    .empower-app {
        padding: 10px;
    }

    .empower-feature-card {
        padding: 15px;
    }

    .empower-feature-card h2 {
        font-size: 1em;
    }

    .empower-feature-card p {
        font-size: 0.8em;
    }
}